import { Suspense, useEffect, useState } from "react";
import PrivateRoute from "./PrivateRoute";
import {
  public_routes,
  private_routes,
  IRoute,
  admin_private_routes,
  globalAdmin_private_routes,
  conditionalRoutes,
  revenueRoute,
} from "routes";
import CircleLoader from "components/atoms/CircleLoader";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import AuthService from "services/auth.service";
import { Chatbot } from "Chatbot";

export default function AppRoutes() {
  const user = useAppSelector((state) => state.auth?.user);
  const [routesAccess, setRoutesAccess] = useState<IRoute[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    // private_routes
    if (user) {
      let private_routesCheck = [];
      if (user.role !== "driver")
        private_routesCheck.push(...admin_private_routes);
      if (user.role === "admin")
        private_routesCheck.push(...globalAdmin_private_routes);
      private_routesCheck.push(...private_routes);
      if (["admin", "company"].includes(user.role) || user.addExpense) {
        private_routesCheck.push(...conditionalRoutes);
      }
      if (["admin"].includes(user.role) || user.company?.displayRevenue) {
        private_routesCheck.push(revenueRoute);
      }
      setRoutesAccess(private_routesCheck);

      setLoading(false);
    } else {
      AuthService.logout();
      setLoading(false);
    }
  }, [user]);

  return (
    <div style={{ minHeight: "90vh", position: "relative" }}>
      {loading ? (
        <CircleLoader />
      ) : (
        <Suspense fallback={<CircleLoader />}>
          {user?.role === "admin" && (
            <Chatbot
              defaultCompany={{
                name: user.company?.fullName,
                id: user.company?._id,
              }}
              isDriver={user?.role === "driver"}
              user={{
                name: `${user.firstName} ${user.lastName}`,
                lang: user.language as any,
                id: user._id,
              }}
            />
          )}
          <Routes>
            {public_routes.map(({ path, element }, i) => (
              <Route key={i} path={path} element={element} />
            ))}

            {routesAccess.map(({ path, element }, i) => (
              <Route
                key={i}
                path={path}
                element={<PrivateRoute path={path}>{element}</PrivateRoute>}
              />
            ))}

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      )}
    </div>
  );
}
