import { modalMapper } from ".";
import { Suspense } from "react";
import { Box, Drawer, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalActions } from "redux/slices/modal";
import CircleLoader from "components/atoms/CircleLoader";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";

function AppModal() {
  const dispatch = useAppDispatch();
  const drawer = useAppSelector((state) => state.modal.drawer);
  const type = useAppSelector((state) => state.modal.type);
  const open = useAppSelector((state) => state.modal.open);
  const width = useAppSelector((state) => state.modal.width);
  const loading = useAppSelector((state) => state.modal.loading);
  const title = useAppSelector((state) => state.modal.title);
  const { t } = useTranslation();
  return drawer ? (
    <Drawer
      open={open}
      onClose={(_: any, reason: any) => {
        if (reason !== "backdropClick") dispatch(modalActions.closeModal());
      }}
      anchor="right"
      sx={{ zIndex: 999 }}
    >
      <Box
        sx={{
          p: 4,
          width: "100%",
          border: "none",
          maxWidth: width,
          maxHeight: "100vh",
          overflowY: "scroll",
          bgcolor: "background.paper",
        }}
      >
        <ErrorBoundary>
          <div style={{ minHeight: "123px", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "-10px",
                // right: "-26px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ margin: 0, padding: 0 }}>{t(`${title}`)}</h2>
                <CloseIcon
                  onClick={() => dispatch(modalActions.closeModal())}
                />
              </div>
            </div>
            {loading && <CircleLoader />}

            <Suspense fallback={<CircleLoader />}>
              <div style={{ paddingTop: "40px" }}>
                {type && modalMapper[type]}
              </div>
            </Suspense>
          </div>
        </ErrorBoundary>
      </Box>
    </Drawer>
  ) : (
    <Modal
      open={open}
      onClose={(_: any, reason: any) => {
        if (reason !== "backdropClick") dispatch(modalActions.closeModal());
      }}
      sx={{
        padding: "16px",
        display: "flex",
        zIndex: 999,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        className="app-modal-body"
        sx={{
          p: 4,
          width: "100%",
          boxShadow: 24,
          border: "none",
          maxWidth: width,
          maxHeight: "93vh",
          overflowY: "scroll",
          bgcolor: "background.paper",
        }}
      >
        <ErrorBoundary>
          <div style={{ minHeight: "123px", position: "relative" }}>
            <CloseIcon
              style={{
                position: "absolute",
                top: "-20px",
                right: "-26px",
                cursor: "pointer",
              }}
              onClick={() => dispatch(modalActions.closeModal())}
            />
            {loading && <CircleLoader />}

            <Suspense fallback={<CircleLoader />}>
              {type && modalMapper[type]}
            </Suspense>
          </div>
        </ErrorBoundary>
      </Box>
    </Modal>
  );
}

export default AppModal;
