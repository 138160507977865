import { TaskState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: TaskState = {
  count: 0,
  refresh: 0,
  tasks: [],
  task: null,
  loading: false,
  taskOptions: [],
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
  },
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    clear: (state) => {
      state.taskOptions = [];
      state.tasks = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addTask: (state, action) => {
      state.tasks.unshift(action.payload);
    },
    updateTask: (state, action) => {
      const { id, task } = action.payload;
      state.tasks.every(({ _id }, i) => {
        if (id === _id) {
          state.tasks[i] = task;
          return false;
        }
        return true;
      });
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setTasks: (
      state,
      action: PayloadAction<{ tasks: any[]; count: number }>
    ) => {
      const { count, tasks } = action.payload;
      let options: SelectOption[] = [];

      tasks?.forEach(({ _id, firstName, lastName }: any) => {
        const option = { value: _id, label: `${firstName} ${lastName || ""}` };
        options.push(option);
      });

      state.count = count;
      state.tasks = tasks;
      state.refreshLoader = false;
      // state.driverOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteTask: (state, action) => {
      const id = action.payload;
      state.tasks.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.tasks.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const taskReducer = taskSlice.reducer;

export const taskActions = taskSlice.actions;
export default taskReducer;
