import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
import { UberStatusState } from ".";

const default_page_size = config.PAGE_SIZE;
const initialState: UberStatusState = {
  count: 0,
  refresh: 0,
  uberStatuses: [],
  uberStatus: null,
  loading: true,
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
  },
};

export const uberStatusSlice = createSlice({
  name: "uberStatus",
  initialState,
  reducers: {
    clear: (state) => {
      state.uberStatuses = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addUberStatus: (state, action) => {
      state.uberStatus.unshift(action.payload);
    },
    updateCompany: (state, action) => {
      const data: any = state?.uberStatus || {};
      data["company"] = action.payload;
      state.uberStatus = data;
    },
    updateUberStatus: (state, action) => {
      const { id, uberStatus } = action.payload;
      state.uberStatuses.every(({ _id }, i) => {
        if (id === _id) {
          state.uberStatuses[i] = uberStatus;
          return false;
        }
        return true;
      });
    },
    setUberStatus: (state, action) => {
      state.uberStatus = action.payload;
    },
    setUberStatuses: (
      state,
      action: PayloadAction<{ uberStatuses: any[]; count: number }>
    ) => {
      const { count, uberStatuses } = action.payload;

      state.count = count;
      state.uberStatuses = uberStatuses;
      state.refreshLoader = false;
      // state.driverOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
  },
});

const uberStatusReducer = uberStatusSlice.reducer;
export const uberStatusActions = uberStatusSlice.actions;
export default uberStatusReducer;
