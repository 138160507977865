import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { companyActions } from "redux/slices/company";
import { formLoaderActions } from "redux/slices/formLoader";
import { uberStatusActions } from "redux/slices/uberstatus";

const url = "/admin/company";

const CompanyService = {
  addCompany: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/create`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.addCompany(company));
      navigate?.(`/companies`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateCompany: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
    } else dispatch?.(companyActions.setCompany({ data: "Not Found" }));

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCompanyUberAlertNotificationDuration: async (
    id: string,
    data: any,
    navigate?: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update-uber-alert/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      // dispatch?.(companyActions.setCompany(company));
      dispatch?.(uberStatusActions.updateCompany(company));

      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  updateOwnCompany: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/updateOwn`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getCompany: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/get/${id}`)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
    } else dispatch?.(companyActions.setCompany({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },

  getOwnCompany: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/getOwn`)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setOwnCompany(company));
    } else dispatch?.(companyActions.setOwnCompany({ data: "Not Found" }));

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getCompanyUsers: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/user/${id}`)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(companyActions.setCompanyUsers(user));
    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  addCompanyUser: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/user`, data)
    );

    if (success) {
      dispatch?.(loaderActions.setLoading(false));
      navigate?.("/companies");
    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },
  getAllCompanies: async (data?: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(companyActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { companies } = success.data.data;
      dispatch?.(companyActions.setCompanies(companies));
    }
    dispatch?.(companyActions.setLoading(false));

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  getAllCompanyCustomers: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/customers/${id}`)
    );

    if (success) {
      const { customers } = success.data.data;
      dispatch?.(companyActions.setCompanyCustomers(customers));
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateCompanyStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/status/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.updateCompany({ id, company }));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  uploadCompanyDocs: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/upload/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateCompanyDocsStatus: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/document/status/${id}`, data)
    );

    if (success) {
      const { company } = success.data.data;
      dispatch?.(companyActions.setCompany(company));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  checkDocumentsExpiry: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/update-expiry`)
    );

    if (success) dispatch?.(modalActions.closeModal());

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CompanyService;
